
import { defineComponent, onMounted, ref } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import Swal from "sweetalert2/dist/sweetalert2.js";
import * as Yup from "yup";
import mAxiosApi from "@/api";
import { MenuComponent } from "@/assets/ts/components";

interface details {
  identifiant: string;
  version: any;
  codeArk: string;
}

export default defineComponent({
  name: "account-settings",
  components: {
    ErrorMessage,
    Field,
    Form,
  },
  setup() {
    const changeIdentifiant = ref(false);
    const identifiantBtn = ref<HTMLElement | null>(null);

    const detailsValidator = Yup.object().shape({
      identifiant: Yup.string().nullable().label("identifiant"),
    });

    const details = ref<details>({
      identifiant: "",
      version: '',
      codeArk: ''
    });

    const saveChanges1 = () => {

        identifiantBtn.value?.setAttribute("data-kt-indicator", "on");
        const data = {
          type: "idservice",
          value: details.value.identifiant
        }

        setTimeout(() => {  
          mAxiosApi.post("setParametrage", data)
          .then(() => {
            Swal.fire({
              text: "Paramétrage de l'identifiant de service effectué !",
              icon: "success",
              confirmButtonText: "Ok",
              buttonsStyling: false,
              customClass: {
                confirmButton: "btn btn-light-primary",
              },
            });
            identifiantBtn.value?.setAttribute("data-kt-indicator", "off");
            changeIdentifiant.value = false;
          })
          .catch((err) => {
            console.error(err);
          });
        }, 500);
    };

    onMounted(async () => {
      MenuComponent.reinitialization();
      setCurrentPageBreadcrumbs("Paramétrage de l'application", []);
      const response = await getAxios("/getParametrage");
      details.value.identifiant = response.results.par_c_identifiant_service;
      details.value.version = parseInt(response.results.par_n_der_version)/100;
      details.value.codeArk = response.results.par_n_codeark;
    });

    return {
      saveChanges1,
      details,
      detailsValidator,
      changeIdentifiant,
      identifiantBtn
    };
  },
});
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export async function getAxios(request: string): Promise<any> {
  const response = await mAxiosApi.get(request);
  return response.data;
}
