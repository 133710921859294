<template>
  <div class="card mb-5 mb-xl-10">
    <div
      class="card-header border-0 cursor-pointer"
      role="button"
      data-bs-toggle="collapse"
      data-bs-target="#kt_parametrage"
      aria-expanded="true"
      aria-controls="kt_parametrage"
    >
      <div class="card-title m-0">
        <h3 class="fw-bolder m-0">Paramétrage du service</h3>
      </div>
    </div>
    <div id="kt_parametrage" class="collapse show">
      <Form
        id="kt_parametrage_form"
        class="form"
        novalidate="novalidate"
        @submit="saveChanges1()"
        :validation-schema="detailsValidator"
      >
        <div class="card-body border-top p-9">
          <div class="row mb-6">
            <label class="col-lg-4 col-form-label fw-bold fs-6">Code ARK</label>
            <div class="col-lg-8">
              <div class="row">
                <div class="col-lg-12 fv-row">
                  <Field
                    type="text"
                    disabled
                    name="codeArk"
                    class="form-control form-control-lg form-control-solid mb-3 mb-lg-0 text-gray-500"
                    :placeholder="'Aucun code ARK'"
                    v-model="details.codeArk"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="row mb-6">
            <label class="col-lg-4 col-form-label fw-bold fs-6">Version des scripts</label>
            <div class="col-lg-8">
              <div class="row">
                <div class="col-lg-12 fv-row">
                  <Field
                    type="text"
                    disabled
                    name="version"
                    class="form-control form-control-lg form-control-solid mb-3 mb-lg-0 text-gray-500"
                    :placeholder="'Aucune version'"
                    v-model="details.version"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="row mb-6">
            <label class="col-lg-4 col-form-label required fw-bold fs-6">identifiant du service d'archives</label>
            <div class="col-lg-8">
              <div class="row">
                <div class="col-lg-12 fv-row">
                  <Field
                    type="text"
                    :disabled="!changeIdentifiant"
                    name="identifiant"
                    class="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                    :class="!changeIdentifiant && 'text-gray-500'"
                    :placeholder="changeIdentifiant ? '' : 'Veuillez saisir votre identifiant'"
                    v-model="details.identifiant"
                  />
                  <div class="fv-plugins-message-container">
                    <div class="fv-help-block">
                      <ErrorMessage name="identifiant" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="w-100 d-flex justify-content-end">
            <div
              class="ms-auto"
              :class="{ 'd-none': changeIdentifiant }"
            >
              <button
              type="button"
                @click="changeIdentifiant = !changeIdentifiant"
                class="btn btn-light fw-boldest"
              >
                Modifier l'identifiant de service
              </button>
            </div>
            <div class="d-flex" v-if="changeIdentifiant">
              <button
                id="kt_changeID_submit"
                type="submit"
                ref="identifiantBtn"
                class="btn btn-primary me-2 px-6"
              >
                <span class="indicator-label">
                  Changer l'identifiant de service
                </span>
                <span class="indicator-progress">
                  Veuillez patienter...
                  <span
                    class="spinner-border spinner-border-sm align-middle ms-2"
                  ></span>
                </span>
              </button>
              <button
                id="kt_changeID_cancel"
                type="button"
                @click="changeIdentifiant = !changeIdentifiant"
                class="btn btn-color-gray-400 btn-active-light-primary px-6"
              >
                Annuler
              </button>
            </div>
          </div>
        </div>
      </Form>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import Swal from "sweetalert2/dist/sweetalert2.js";
import * as Yup from "yup";
import mAxiosApi from "@/api";
import { MenuComponent } from "@/assets/ts/components";

interface details {
  identifiant: string;
  version: any;
  codeArk: string;
}

export default defineComponent({
  name: "account-settings",
  components: {
    ErrorMessage,
    Field,
    Form,
  },
  setup() {
    const changeIdentifiant = ref(false);
    const identifiantBtn = ref<HTMLElement | null>(null);

    const detailsValidator = Yup.object().shape({
      identifiant: Yup.string().nullable().label("identifiant"),
    });

    const details = ref<details>({
      identifiant: "",
      version: '',
      codeArk: ''
    });

    const saveChanges1 = () => {

        identifiantBtn.value?.setAttribute("data-kt-indicator", "on");
        const data = {
          type: "idservice",
          value: details.value.identifiant
        }

        setTimeout(() => {  
          mAxiosApi.post("setParametrage", data)
          .then(() => {
            Swal.fire({
              text: "Paramétrage de l'identifiant de service effectué !",
              icon: "success",
              confirmButtonText: "Ok",
              buttonsStyling: false,
              customClass: {
                confirmButton: "btn btn-light-primary",
              },
            });
            identifiantBtn.value?.setAttribute("data-kt-indicator", "off");
            changeIdentifiant.value = false;
          })
          .catch((err) => {
            console.error(err);
          });
        }, 500);
    };

    onMounted(async () => {
      MenuComponent.reinitialization();
      setCurrentPageBreadcrumbs("Paramétrage de l'application", []);
      const response = await getAxios("/getParametrage");
      details.value.identifiant = response.results.par_c_identifiant_service;
      details.value.version = parseInt(response.results.par_n_der_version)/100;
      details.value.codeArk = response.results.par_n_codeark;
    });

    return {
      saveChanges1,
      details,
      detailsValidator,
      changeIdentifiant,
      identifiantBtn
    };
  },
});
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export async function getAxios(request: string): Promise<any> {
  const response = await mAxiosApi.get(request);
  return response.data;
}
</script>
